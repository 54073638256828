import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, googleLogin, fetchUserData, updateUserData } from '../../redux/slices/authSlice';
import UserDetailsModal from '../../shared/UserDetailsModal';
import { useNavigate } from 'react-router-dom';

const Register = ({ switchToLogin }) => {
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        "firstName": '',
        "lastName": '',
        "email": '',
        "password": '',
        "phone": '',
        "address": '',
        "pincode": ''
    });
    
    const dispatch = useDispatch();
    const { loading, error, user } = useSelector((state) => state.auth);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'pincode' ? parseInt(value, 10) : value
        });
    };

    const navigate = useNavigate();
    useEffect(() => {
        if (user) {
            if (user.firstName === null) {
                console.log("object");
                setShowModal(true);
            } else{
                window.location.reload()
            }
        }
    }, [user, navigate]);
    const handleRegister = (e) => {
        e.preventDefault();
        console.log(formData)
        dispatch(registerUser(formData));

    };

    const handleGoogleLogin = async() => {
        await dispatch(googleLogin()).unwrap();
        setTimeout(() => {
            dispatch(fetchUserData());
        }, 100);
    };

    const handleModalSubmit = (details) => {
        dispatch(updateUserData(details));
        setShowModal(false);
    };


    return (
        <div className='font-PlayfairDisplay'>
            <h2 className='text-black text-4xl font-extrabold text-center pb-2'>Register</h2>
            <form onSubmit={handleRegister} className='text-xl text-center'>
                <input
                    className='w-full border-b mt-[15px] outline-none'
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                />
                <input
                    className='w-full border-b mt-[15px] outline-none'
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                />
                <input
                    className='w-full border-b mt-[15px] outline-none'
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <input
                    className='w-full border-b mt-[15px] outline-none'
                    type="tel"
                    name="phone"
                    placeholder="Phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                />
                <input
                    className='w-full border-b mt-[15px] outline-none'
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                />
                <input
                    className='w-full border-b mt-[15px] outline-none'
                    type="number"
                    name="pincode"
                    placeholder="Pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    required
                />
                <input
                    className='w-full border-b mt-[15px] outline-none'
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                />
                <button type="submit" disabled={loading} className='mt-[23px] text-lg font-semibold bg-[#754F23] text-white rounded-xl px-10 py-1'>
                    {loading ? 'Loading...' : 'Register'}
                </button>
                {error && <p>{error}</p>}
            </form>
            <h2 className="mt-4 text-[#969ab8] text-[15px] text-center">
            Already Have an Account?{' '}
                <button onClick={switchToLogin} className="text-[15px] text-[#754f23]">
                    Log In
                </button>
                <p className='font-extrabold text-[#C1C1C1]'>-OR-</p>
            </h2>
            <button onClick={handleGoogleLogin} disabled={loading} className='mt-3 text-[#928f8f] border-2 flex m-auto gap-1 px-2 py-1 rounded-lg'>
                <img src="https://img.icons8.com/?size=100&id=17949&format=png&color=000000" alt="google logo"  className='h-8 w-8'/>
                <p className='m-auto'> Continue with Google</p>
            </button>
            {showModal && <UserDetailsModal onSubmit={handleModalSubmit} onClose={() => setShowModal(false)} />}
        </div>
    );
};

export default Register;
